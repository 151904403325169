import ReactDOM from "react-dom"
import React, { Suspense, useEffect, useRef, useMemo } from "react"
import { Canvas, Dom, useLoader, useFrame } from "react-three-fiber"
import { TextureLoader, LinearFilter } from "three"
import lerp from "lerp"
import { Text, MultilineText } from "./components/Text"
import Diamonds from "./diamonds/Diamonds"
import Plane from "./components/Plane"
import { Block, useBlock } from "./blocks"
import state from "./store"
import "./styles.css"



const Home = () => {
  function Startup() {
    const ref = useRef()
    useFrame(() => (ref.current.material.opacity = lerp(ref.current.material.opacity, 0, 0.025)))
    return <Plane ref={ref} color="#0e0e0f" position={[0, 0, 200]} scale={[100, 100, 1]} />
  }
  
  function Paragraph({ image, index, offset, factor, header, aspect, text, clickon }) {
    const { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock()
    const size = aspect < 1 && !mobile ? 0.65 : 1
    const alignRight = (canvasWidth - w * size - margin) / 2
    const pixelWidth = w * state.zoom * size
    const left = !(index % 2)
    const color = index % 2 ? "#D40749" : "#D40749"
    return (
      <Block factor={factor} offset={offset}>
        <group position={[left ? -alignRight : alignRight, 0, 0]}>
          <Plane map={image} args={[1, 1, 32, 32]} shift={75} size={size} aspect={aspect} scale={[w * size, (w * size) / aspect, 1]} frustumCulled={false} />
          <Dom
            style={{ width: pixelWidth / (mobile ? 1 : 2), textAlign: left ? "left" : "right" }}
            position={[left || mobile ? (-w * size) / 2 : 0, (-w * size) / 2 / aspect - 0.4, 1]}>
            <div tabIndex={index}>{text}</div>
          </Dom>
          <Text style={{overflowY: "hidden"}} left={left} right={!left} size={w * 0.04} color={color} top position={[((left ? -w : w) * size) / 2, (w * size) / aspect / 2 + 0.5, -1]}>
            {header}
          </Text>
          <Block factor={0.2}>
            <Text style={{overflowY: "hidden"}} opacity={0.5} size={w * 0.1} color="#1A1E2A" position={[((left ? w : -w) / 2) * size, (w * size) / aspect / 1.5, -10]}>
              {"0" + (index + 1)}
            </Text>
          </Block>
        </group>
      </Block>
    )
  }
  
  function Content() {
    const images = useLoader(
      TextureLoader,
      state.paragraphs.map(({ image }) => image)
    )
    useMemo(() => images.forEach(texture => (texture.minFilter = LinearFilter)), [images])
    const { contentMaxWidth: w, canvasWidth, canvasHeight, mobile } = useBlock()
    return (
      <>
        <Block factor={1} offset={0}>
          <Block factor={1.2}>
            <Text left size={w * 0.07} position={[-w / 2, 0.5, -1]} color="##CC00FF">
              CROWN MONKEY
            </Text>
          </Block>
          <Block factor={1.0}>
            <Dom position={[-w / 3.2, -w * 0.08 + 0.25, -1]}> {mobile ? <br /> : " "}</Dom>
          </Block>
        </Block>
        <Block factor={1.2} offset={5.7}>
          <MultilineText top left size={w * 0.15} lineHeight={w / 5} position={[-w / 2.8, 0, -1]} color="#2fe8c3" text={"crown\ncrown\ncrown"} />
        </Block>
        {state.paragraphs.map((props, index) => (
          <Paragraph style={{overflowY: 'hidden'}} key={index} index={index} {...props} image={images[index]} />
        ))}
        {state.stripes.map(({ offset, color, height }, index) => (
          <Block key={index} factor={-1.5} offset={offset}>
            <Plane args={[50, height, 32, 32]} shift={-4} color={color} rotation={[0, 0, Math.PI / 8]} position={[0, 0, -10]} />
          </Block>
        ))}
        <Block factor={1.25} offset={8}>
          <Dom className="bottom-left" position={[-canvasWidth / 2, -canvasHeight / 2, 0]}>
            Claim your own crown
          </Dom>
        </Block>
      </>
    )
  }
  const scrollArea = useRef()
  const onScroll = e => (state.top.current = e.target.scrollTop)
  useEffect(() => void onScroll({ target: scrollArea.current }), [])

    return (
        <div className="home">
             
      <Canvas className="canvas" concurrent pixelRatio={1} orthographic camera={{ zoom: state.zoom, position: [0, 0, 500] }}>
        <Suspense fallback={<Dom center className="loading" children="Loading..." />}>
          <Content />
          <Diamonds />
          <Startup />
        </Suspense>
      </Canvas>
      <div className="scrollArea" ref={scrollArea} onScroll={onScroll} onClick="www.crown-monkey.com">
        {new Array(state.sections).fill().map((_, index) => (
          <div key={index} id={"0" + index} style={{ height: `${(state.pages / state.sections) * 100}vh` }}/>
        ))}
      </div>
      <div className="frame">
        <h1 className="frame__title">Find your own crown</h1>
        <div className="frame__links">
        <a className="frame__link" href="http://product-rnd.crown-monkey.com/">
            Product & Research
          </a>
          <a className="frame__link" href="http://community.crown-monkey.com">
            Community
          </a>
          <a className="frame__link" href="/Project">
            Projects
          </a>
          <a className="frame__link" href="http://team.crown-monkey.com/">
            Team
          </a>
        </div> 
        <div className="frame__nav">
          <a className="frame__link" href="#00" children="" />
          <a className="frame__link" href="#01" children="A" />
          <a className="frame__link" href="#02" children="B" />
          <a className="frame__link" href="#03" children="C" />
          <a className="frame__link" href="#04" children="D" />
          <a className="frame__link" href="#05" children="E" />
          <a className="frame__link" href="#07" children="F" />
        </div>
      </div>
        </div>
    )
}

export default Home

// --------------------------------------- Project List
export const ProjectList = [
  {
    img: "https://i.imgur.com/CqLP5Jf.png",
    title: "Transparency in Carbon Credit",
    description: "Introducing blockchain to bring transparency in carbon credit",
    tech_stack: "Blockchain of Things",
    github_url: "https://www.youtube.com/watch?v=R8Pu3k9w3uE",
    demo_url: "https://www.youtube.com/watch?v=R8Pu3k9w3uE",
  },
  {
    img: "https://i.imgur.com/7OdnLPK.jpg",
    title: "Syntup",
    description: "CM's Ecommerce website made with MERN STACK.",
    tech_stack: "MERN STACK",
    github_url: "https://github.com/DivyaKalash/readme/blob/main/SYNTUP.md",
    demo_url: "https://github.com/DivyaKalash/readme/blob/main/SYNTUP.md",
  },
  {
    img: "https://i.imgur.com/TxCtSj2.jpg",
    title: "Development of Information Technology Telecom Chatbot ",
    description:"Development of Information Technology Telecom Chatbot: An Artificial Intelligence and Machine Learning Approach",
    tech_stack: "DiscordJs, Javascript, Machine Learning",
    github_url: "https://ieeexplore.ieee.org/document/9445354",
    demo_url: "https://ieeexplore.ieee.org/document/9445354",
  },
  {
    img: "https://i.imgur.com/t8JzrKB.gif",
    title: "Virtual Office Prototype",
    description: "Virtual Office Prototype: A Unity-2D work Simulation",
    tech_stack: "Unity",
    github_url: "https://ieeexplore.ieee.org/document/9673456",
    demo_url: "https://ieeexplore.ieee.org/document/9673456",
  },
  {
    img: "https://i.imgur.com/MlHEF8w.jpg",
    title: "Dr. Rush - Corona Game",
    description: "Join the doctor on her adventure to save humanity from a very infectious disease?",
    tech_stack: "UNITY, C#, AE",
    github_url: "https://enigma-kun.itch.io/corona-frenzy",
    demo_url: "https://enigma-kun.itch.io/corona-frenzy",
  },
  {
    img: "https://i.imgur.com/UKN71VZ.jpg",
    title: "medEase",
    description:
      "medEase means medicine available easily. medEase application resolves this problem by making direct contact with the shopkeepers around us and getting updated by them about the availability of the medicines.",
    tech_stack: "MERN STACK",
    github_url: "https://github.com/DivyaKalash/readme/blob/main/MEDEASE.md",
    demo_url: "https://github.com/DivyaKalash/readme/blob/main/MEDEASE.md",
  },
  {
    img: "https://i.imgur.com/Brdzp7t.png",
    title: "Fake News Detection",
    description:
      "Exploring how machine learning technologies could be leveraged to combat fake news.",
    tech_stack: "Machine Learning, Flask",
    // github_url: "https://github.com/gurupawar/ecommerce-react-app",
    // demo_url: "https://ecommerce-react-app.vercel.app/",
  },
  {
    img: "https://i.imgur.com/WydMk5w.png",
    title: "Block Vote",
    description:
      "Simple voting system made using blockchain votes system",
    tech_stack: "MERN STACK, Blockchain",
    github_url: "https://github.com/DivyaKalash/readme/blob/main/BLOCKVOTE.md",
    demo_url: "https://github.com/DivyaKalash/readme/blob/main/BLOCKVOTE.md",
  },
];

// --------------------------------------- Skills

// export const stackList = [
//   {
//   //   img: "",
//   //   name: "",
//   // },
//   // {
//   //   img: "https://i.imgur.com/xgn3xv4.png",
//   //   name: "React",
//   // },
//   // {
//   //   img: "https://gurupawar.github.io/portfolio/assets/skill/javascript.svg",
//   //   name: "JavaScript",
//   // },
//   // {
//   //   img: "https://i.imgur.com/F56Id2O.png",
//   //   name: "Node.js",
//   // },
//   // {
//   //   img: "https://i.imgur.com/NwuaVWn.png",
//   //   name: "Unity",
//   // },
//   // {
//   //   img: "https://gurupawar.github.io/portfolio/assets/skill/git.svg",
//   //   name: "Git",
//   // },
//   // {
//   //   img: "https://i.imgur.com/mPleBue.png",
//   //   name: "Crown Monkey",
//   // },
// ];

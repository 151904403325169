import ReactDOM from "react-dom"
import React, { Suspense, useEffect, useRef, useMemo } from "react"
// import { Canvas, Dom, useLoader, useFrame } from "react-three-fiber"
// import { TextureLoader, LinearFilter } from "three"
// import lerp from "lerp"
// import { Text, MultilineText } from "./components/Text"
// import Diamonds from "./diamonds/Diamonds"
// import Plane from "./components/Plane"
// import { Block, useBlock } from "./blocks"
// import state from "./store"
import "./styles.css"
// import "./base.css"
import Home from "./home"
import link from "./link"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Project from "./pages/Project"





function App() {
  
  return (
   <div>
   <Router>
   <Switch>
     <Route path="/" exact component={Home}/>
     <Route path="/about" exact component={link}/>
     <Route path="/project" exact component={Project}/>
     </Switch>
     </Router>
   </div>
  )
}

ReactDOM.render(<App />, document.getElementById("root"))

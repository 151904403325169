import React from "react";
import { stackList } from "../../data/ProjectData";
import {
  Image,
  Technologies,
  Tech,
  TechImg,
  TechName,
  ContactWrapper,
} from "./AboutElements";
function About() {
  return (
    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">Description</div>
        <div className="BigCard">
          <Image
            src="https://i.imgur.com/H5LB15u.png"
            alt="man-svgrepo"
          />
          <div className="AboutBio">
            Hello! We are <strong>Crown Monkey</strong> here we try to make better working world by implementing tech solutions for rising problems.
          </div>
          <div className="AboutBio tagline2">
          </div>
          {/* <Technologies>
            {stackList.map((stack, index) => (
              <Tech key={index} className="tech">
                <TechImg src={stack.img} alt={stack.name} />
                <TechName>{stack.name}</TechName>
              </Tech>
            ))}
          </Technologies> */}
        </div>
      </div>
    </ContactWrapper>
  );
}

export default About;

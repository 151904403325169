import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 8,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "Product & Research",
      image: "/PROJECT1.jpg",
      clickon: "www.crown-monkey.com",
      aspect: 1.51,
      text: "Our carbon credit blockchain application is a cutting-edge solution designed to revolutionize the carbon credit market. It leverages blockchain technology to provide transparency, security, and efficiency in the trading and verification of carbon credits. By digitizing the entire process, our application simplifies and automates the tracking, issuance, transfer, and retirement of carbon credits, making it easier for businesses and individuals to participate in the global effort to reduce carbon emissions."
    },
    {
      offset: 2,
      factor: 2.0,
      header: "Community",
      image: "/ONGOING PROJECTS.jpg",
      clickon: "www.crown-monkey.com",
      aspect: 1.5,
      text:
        "Community of Crown Monkey provides a vibrant and inclusive platform that brings together individuals, organizations, and experts passionate about combating climate change and promoting sustainable practices. It serves as a virtual hub where stakeholders can collaborate, share knowledge, and actively participate in the global carbon credit market facilitated by blockchain technology."
    },
    {
      offset: 3,
      factor: 2.25,
      header: "Projects",
      image: "/COMMUNITY.jpg",
      clickon: "www.crown-monkey.com",
      aspect: 1.5037,
      text:
        "Welcome to our vibrant and collaborative community dedicated to exploring the possibilities of open source software and blockchain applications. Our community serves as a hub for developers, enthusiasts, and innovators who are passionate about leveraging the power of open source technology and blockchain to drive meaningful change and revolutionize various industries."
    },
    {
      offset: 4,
      factor: 2.0,
      header: "Contact Us",
      image: "/channels.jpg",
      clickon: "www.crown-monkey.com",
      aspect: 0.665,
      text:
        "In this section we welcome all of you from all across the world to join us and help us to grow the community. In the discord community we will conduct amazing sessions all over the week so that you dont feel idle and enjoy every bit of being part of the community. Daily quizzes and workshop on several hot topics and tech sessions about new things will  be some of the few things which we will conduct."
    },
    {
      offset: 5,
      factor: 1.75,
      header: "About",
      image: "/aboutus.jpg",
      clickon: "www.crown-monkey.com",
      aspect: 1.55,
      text:
        "While there are hundreds of portfolio websites there are only handful which are comprehensive and responsive, offering full scope for contributing in open source projects catering to your needs.We are a tech team of few members which offers its users a real dive into world of open source projects."
    },
    { offset: 7, factor: 1.05, header: "Wanna Join us?", image: "/wanna join  us.jpg", aspect: 1.77, text: "Come lets join the Crown Monkey(CM) community and make this tech-monkey famous. You  can connect us on linkedin and Instagram for weekly posts and on Youtube for several amazing videos. You will get the access to github where according to your interst you can contribute in open source projects."}
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 }
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 0.6, factor: 1.8 },
    { x: 2, offset: 1.1, pos: new Vector3(), scale: 0.8, factor: 2.1 },
    { x: -5, offset: 2, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 0, offset: 3.2, pos: new Vector3(), scale: 0.8, factor: 1.75 },
    { x: 0, offset: 4, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 2, offset: 5.5, pos: new Vector3(), scale: 1.25, factor: 0.85 },
    { x: -5, offset: 7, pos: new Vector3(), scale: 0.8, factor: 2 },
    { x: 0, offset: 8, pos: new Vector3(), scale: 1.5, factor: 6 }
  ],
  top: createRef()
}

export default state
